class u extends Error {
  code;
  action_id;
  action;
  constructor(t, e, n, r) {
    super(t), this.code = e, this.action_id = n, this.action = r;
  }
}
const p = 2 ** -1023;
function M(s, t, e = 1e-6) {
  if (s === t)
    return !0;
  const n = Math.abs(s), r = Math.abs(t), i = Math.abs(s - t);
  return s === 0 || t === 0 || n + r < p ? i < e * p : i / Math.min(n + r, Number.MAX_VALUE) < e;
}
function $(s, t) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: t
  }).format(s);
}
function w(s, t) {
  const e = s instanceof URL ? s : new URL(s.toString()), n = new URL(
    t instanceof URL || /^([^:/]+:|\/)/.test(t) ? t : e.pathname.replace(/\/$/, "") + "/" + t.replace(/^\.?\//, ""),
    e
  );
  for (const [r, i] of e.searchParams.entries())
    n.searchParams.has(r) || n.searchParams.append(r, i);
  return n;
}
async function S(s, t, e, {
  prepare: n = T,
  handle: r = C,
  autoUrlCacheBust: i = !0,
  ...o
} = {}) {
  s = s.toUpperCase();
  const a = new URL(t, globalThis.location?.origin);
  if (s === "GET" && (i && a.searchParams.append("ts", Date.now().toString()), e)) {
    for (const h in e)
      a.searchParams.append(h, String(e[h]));
    e = void 0;
  }
  const c = await fetch(a, { ...n(o, e), method: s });
  return r(c);
}
function _(s, t, e) {
  return S("GET", s, t, e);
}
function v(s, t, e) {
  return S("POST", s, t, e);
}
function T(s, t) {
  return {
    ...s,
    headers: {
      ...s.headers ?? {},
      ...t ? { "Content-Type": "application/json" } : {}
    },
    body: t ? JSON.stringify(t) : s.body ?? null
  };
}
function C(s) {
  return s.headers.get("Content-Type")?.includes("json") ? s.json() : s.headers.get("Content-Type")?.includes("text") ? s.text() : s.blob();
}
class A extends Error {
  static name = "RetryError";
  static code = "RETRY_ERROR";
  reason;
  action;
  retryAborted;
  constructor(t, e, n = !1) {
    super("Failed to run action after maximum number of attempts"), this.action = t, this.reason = e, this.retryAborted = n;
  }
}
function E(s, t) {
  if (typeof s == "function" && (t = s, s = {}), !t)
    throw new Error("No action provided");
  const { maxAttempts: e = 5, delay: n = 100, jitter: r = 50, shouldRetry: i = () => !0 } = s;
  return new Promise((o, a) => {
    let c = 0;
    function h() {
      c++, t().then(o).catch((l) => {
        !i(l) || c >= e ? a(new A(t, l, c < e)) : setTimeout(
          h,
          2 ** (c - 1) * n + r * (Math.random() * 2 - 1)
        );
      });
    }
    h();
  });
}
const d = Symbol("cancel");
function L(s, { onRestart: t = "stop" } = {}) {
  const e = [];
  let n = null, r = null;
  const i = (h) => {
    if (n && (clearTimeout(n), n = null, h && t == "complete" && (!r || r(d) !== d)))
      for (; e.length > 0; ) {
        const { callback: l } = e.shift();
        if (l(d) === d)
          break;
      }
    e.length = 0;
  }, o = () => i(!1), a = () => {
    if (e.length > 0) {
      const { callback: h, delay: l } = e.shift();
      r = h, n = setTimeout(() => {
        r = null, h(d) === d ? o() : a();
      }, l);
    }
  }, c = (h) => (l) => (e.push({ callback: l, delay: h }), { wait: c, cancel: o });
  return Object.assign(
    (h) => {
      i(!0);
      const l = c(s)(h);
      return Promise.resolve().then(a), l;
    },
    { cancel: o }
  );
}
function j(s, t) {
  return L(s)(t);
}
function R() {
  const s = crypto.getRandomValues(new Uint8Array(16));
  s[6] = s[6] & 15 | 64, s[8] = s[8] & 63 | 128;
  const t = Array.from(s).map((e) => e.toString(16).padStart(2, "0")).join("");
  return `${t.slice(0, 8)}-${t.slice(8, 12)}-${t.slice(12, 16)}-${t.slice(16, 20)}-${t.slice(20)}`;
}
class F {
  #t;
  gameId;
  gameSessionId;
  constructor(t) {
    this.#t = new URL(t);
  }
  async getBalance() {
    return this.#e(
      "players/balance",
      {
        game_session_id: this.gameSessionId,
        game_id: this.gameId
      },
      {
        balance: "number",
        currency: "string"
      }
    );
  }
  async getSeeds() {
    return this.#e(`games/keys/${this.gameSessionId}`, null, {
      client_seed: "string",
      server_seed_hashed: "string",
      next_server_seed_hashed: "string"
    });
  }
  async verifySeeds(t) {
    return this.#n(
      `games/keys/verify/${this.gameSessionId}`,
      {
        type: "VerifySeeds",
        game_id: this.gameId,
        data: t
      },
      {
        type: "string",
        action: "string",
        data: "object"
      }
    );
  }
  async setClientSeed(t) {
    return this.#n(`games/keys/${this.gameSessionId}`, { client_seed: t });
  }
  #e(t, e, n) {
    return this.#s(), _(w(this.#t, t), e, {
      handle: this.#i(`GET ${t}`, n || {})
    });
  }
  #n(t, e, n) {
    return this.#s(), v(w(this.#t, t), e, {
      handle: this.#i(`POST ${t}`, n || {})
    });
  }
  #i(t, e) {
    return async (n) => {
      const r = await n.json();
      if (!n.ok)
        throw y(r, {
          type: "string",
          message: "string",
          code: "string",
          action_id: "string"
        }) && r.type === "Error" ? new u(r.message, r.code, r.action_id) : new Error(n.statusText);
      if (!y(r, e)) {
        const i = new Error(`Invalid ${t} response`);
        throw console.error(i, r), i;
      }
      return r;
    };
  }
  #s() {
    if (!this.gameId || !this.gameSessionId)
      throw new Error("Game ID and Game Session ID must be set");
  }
}
function y(s, t) {
  if (typeof s != "object" || s === null)
    return !1;
  for (const [e, n] of Object.entries(t)) {
    const r = s, i = n.endsWith("?");
    if (i && (!(e in r) || r[e] === void 0))
      continue;
    const o = i ? n.slice(0, -1) : n;
    if (!(e in r) || typeof r[e] !== o)
      return !1;
  }
  return !0;
}
const f = /* @__PURE__ */ new Map();
let m = null;
function I(s) {
  if (f.has(s))
    return f.get(s);
  let t = !1;
  return m || (m = document.createElement("audio")), t = m.canPlayType(s) === "probably", f.set(s, t), t;
}
async function k(s, t) {
  const e = t.find(({ type: n }) => I(n));
  return e ? await g(s, e.src) : new Promise((n, r) => {
    try {
      const i = document.createElement("audio");
      for (const { src: o, type: a } of t) {
        const c = document.createElement("source");
        c.src = o, c.type = a, i.appendChild(c);
      }
      i.addEventListener(
        "canplaythrough",
        () => {
          const o = i.currentSrc || i.src, a = t.find(({ src: c }) => c === o)?.type;
          a && f.set(a, !0), g(s, o).then(n, r);
        },
        { once: !0 }
      );
    } catch (i) {
      r(i && i instanceof Error ? i : new Error("Failed to load audio"));
    }
  });
}
async function g(s, t) {
  const n = await (await fetch(t)).arrayBuffer();
  return await s.decodeAudioData(n);
}
class b {
  #t;
  #e = null;
  constructor(t, e) {
    this.#t = t, k(t.offlineContext, e).then((n) => {
      this.#e = n;
    }).catch(console.error);
  }
  async play() {
    return this.playSegment();
  }
  async playSegment(t, e, n) {
    if (!this.#e)
      return;
    if (this.#t.muted)
      return new Promise((o) => setTimeout(o, this.#e.duration * 1e3));
    const i = (await this.#t.resume()).createBufferSource();
    if (i.buffer = this.#e, !!this.#t.connect(i))
      return new Promise((o) => {
        i.addEventListener("ended", () => o(), { once: !0 }), i.start(n, t, e);
      });
  }
}
class x {
  #t;
  #e;
  constructor(t, e) {
    const { sources: n, sprites: r } = e;
    this.#e = new b(t, n);
    for (const i of Object.values(r))
      if (i.length !== 2)
        throw new Error("Sprite spans must have exactly two elements.");
    this.#t = r;
  }
  async play(t) {
    if (this.#t[t])
      return this.#e.playSegment(...this.#t[t]);
  }
}
class B {
  #t = null;
  #e = null;
  #n = 1;
  #i = !1;
  #s = !0;
  #a = [];
  #l = new AbortController();
  #u = !1;
  offlineContext;
  constructor() {
    this.offlineContext = new OfflineAudioContext(1, 2, 44100);
    const t = new AbortController(), e = () => {
      if (t.abort(), this.#t)
        return;
      this.#t = new AudioContext(), this.#e = this.#t.createGain(), this.#e.connect(this.#t.destination), this.#s = this.#r() && ("ontouchstart" in globalThis || "onclick" in globalThis), this.#c().then(() => {
        for (const i of this.#a)
          i(this.#t);
      }).catch(console.error);
      const n = new AbortController(), r = () => {
        this.#c().then(() => {
          this.#s || n.abort();
        }).catch(console.error);
      };
      for (const i of ["touchend", "mousedown", "keypress"])
        document.addEventListener(i, r, {
          capture: !0,
          signal: n.signal
        });
    };
    for (const n of ["touchstart", "touchend", "mousedown", "keypress"])
      document.addEventListener(n, e, {
        capture: !0,
        signal: t.signal
      });
    globalThis.addEventListener(
      "focus",
      () => {
        this.#t && (this.#r() || !this.#s) && this.#t.resume().catch(console.error);
      },
      {
        signal: this.#l.signal
      }
    ), globalThis.addEventListener(
      "blur",
      () => {
        this.#t && !this.#r() && this.#t.suspend().catch(console.error);
      },
      {
        signal: this.#l.signal
      }
    );
  }
  dispose() {
    this.#u = !0, this.#l.abort(), this.#t && (this.#t.close(), this.#t = null), this.#e && (this.#e.disconnect(), this.#e = null);
  }
  createSound(t) {
    return new b(this, t);
  }
  createSoundSprite(t) {
    return new x(this, t);
  }
  connect(t) {
    return this.#e ? (t.connect(this.#e), !0) : !1;
  }
  async resume() {
    if (!this.#t) {
      if (this.#u)
        throw new Error("Audio controller has been disposed");
      return new Promise((t) => {
        this.#a.push(t);
      });
    }
    return this.#r() && await this.#t.resume(), this.#t;
  }
  #r() {
    if (!this.#t)
      return !0;
    const t = this.#t.state;
    return t === "suspended" || t === "interrupted";
  }
  get muted() {
    return this.#i;
  }
  set muted(t) {
    this.#i !== t && (this.#i = t, this.#e && (this.#e.gain.value = t ? 0 : this.#n));
  }
  get volume() {
    return this.#n;
  }
  set volume(t) {
    this.#n !== t && (this.#n = t, !this.#i && this.#e && (this.#e.gain.value = t));
  }
  async #c() {
    if (!this.#s || !this.#t)
      return;
    const t = this.#t.createBufferSource();
    t.buffer = this.#t.createBuffer(1, 1, 22050), t.connect(this.#t.destination), t.start(0, 0, 0), this.#r() && await this.#t.resume(), this.#t.state === "running" && (this.#s = !1);
  }
}
function N(s, t) {
  return s.action === t;
}
function U(s, t) {
  return s.action === t;
}
function q(s) {
  return s.type === "GameUpdate";
}
function G(s) {
  return s.type === "Authenticate";
}
function O(s) {
  return s.type === "GameState";
}
class D {
  gameId;
  #t;
  #e = /* @__PURE__ */ new Map();
  #n = /* @__PURE__ */ new Set();
  #i = /* @__PURE__ */ new Set();
  #s = [];
  #a;
  #l;
  #u;
  #r = /* @__PURE__ */ new Map();
  #c;
  #p;
  #o = !1;
  #h = "closed";
  #d = !1;
  #f = [];
  #w;
  /**
   * Create a new game client.
   *
   * @param configuration - The game client configuration.
   */
  constructor({
    gameId: t,
    transports: e,
    requireAuthentication: n = !0,
    allowConcurrentActions: r = !1,
    actionTimeout: i = 0,
    autoRetryAttempts: o = 0,
    autoRetryDelay: a = 50
  }) {
    this.gameId = t, this.#t = e, this.#a = n, this.#l = r, this.#u = i, this.#p = a, this.#c = typeof o == "number" ? () => o : o, this.allowedActions.add(this.#a ? "auth" : "Open");
    for (const c of e)
      c.onMessage(this.#_), c.onReadyStateChanged(this.#v);
    this.#v();
  }
  /**
   * Whether the client is ready to send and receive messages (i.e., whether it is connected).
   */
  get isReady() {
    return this.#h === "ready";
  }
  /**
   * The current ready state of the client.
   */
  get readyState() {
    return this.#h;
  }
  /**
   * Whether the client is authenticated.
   *
   * GameEvent actions can only be sent when authenticated.
   */
  get isAuthenticated() {
    return this.#d;
  }
  /**
   * The message history.
   */
  get history() {
    return this.#s;
  }
  /**
   * Add a handler for the ready state change.
   *
   * @param handler - The ready state change handler to add.
   * @returns This client instance.
   */
  onReadyStateChanged(t) {
    return this.#n.add(t), Promise.resolve().then(() => t(this.#h)), this;
  }
  /**
   * Add an error handler.
   *
   * @param handler - The error handler to add.
   * @returns This client instance.
   */
  onError(t) {
    return this.#i.add(t), this;
  }
  /**
   * Send an authentication request.
   *
   * @param data - The authentication data.
   * @returns A promise that resolves with the authentication action response.
   */
  async authenticate(t) {
    return this.sendAndAwait("Authenticate", "auth", {}, t);
  }
  /**
   * Send an "Open" action to start the game.
   *
   * @returns A promise that resolves with the initial game state.
   */
  async open(t) {
    return this.#d ? this.sendAndAwait("Open", t) : this.authenticate(t).then(() => this.sendAndAwait("Open", t));
  }
  /**
   * The set of allowed actions in the current game state.
   *
   * Sending an action that is not in this set will throw an error.
   */
  allowedActions = /* @__PURE__ */ new Set();
  /**
   * Check if an action is allowed in the current game state.
   *
   * By default, this method checks if the action is in the `allowedActions` set.
   * Subclasses can override this method to implement custom action permission logic.
   *
   * @param action - The action to check.
   * @returns `true` if the action is allowed, otherwise `false`.
   */
  isActionAllowed(t) {
    const e = t.action;
    return this.#d ? e === "GameState" || this.allowedActions.has(e) : e === "auth";
  }
  sendGameEvent(t, e) {
    const n = this.#g(t, e);
    return n ? (this.#y(n), n.action_id) : "";
  }
  /**
   * Prepares a GameEvent action.
   *
   * If the client is not authenticated, an error will be raised.
   *
   * @param action - The action to send.
   * @param data - The action payload.
   * @returns The action object to send.
   */
  #g(t, e, n = !1) {
    return this.#a && !this.#d ? (n || this.#m(new u("Not authenticated", "AUTH_FAILED", "")), null) : this.#E(
      {
        type: "GameEvent",
        action: t,
        game_id: this.gameId,
        round_id: this.#w,
        data: e ?? {}
      },
      n
    );
  }
  #m(t) {
    const e = new ErrorEvent("error", { error: t });
    for (const n of this.#i)
      n(e);
    if (!e.defaultPrevented)
      throw t;
    return t;
  }
  sendSessionAction(t, e, n) {
    const r = this.#S(t, e, n);
    return r ? (this.#y(r), r.action_id) : "";
  }
  /**
   * Prepares a non-GameEvent action with a payload.
   *
   * @param type - The type of the action to send.
   * @param action - The action to send.
   * @param data - The action payload.
   * @returns The action object to send.
   */
  #S(t, e, n, r = !1) {
    return this.#E(
      {
        type: t,
        action: e,
        game_id: this.gameId,
        data: n ?? {}
      },
      r
    );
  }
  sendAndAwait(t, e, n, r) {
    let i, o, a = {}, c = r;
    return typeof e == "string" ? (i = t, o = e, typeof n == "function" ? c = n : a = n) : (i = "GameEvent", o = t, typeof e == "function" ? c = e : (a = e, c = n)), this.#A(i, o, a, c);
  }
  #A(t, e, n, r, i = !1) {
    const o = t !== "GameEvent" ? this.#S(
      t,
      e,
      n,
      i
    ) : this.#g(
      e,
      n,
      i
    );
    return o ? E(
      {
        maxAttempts: this.#c(t, e, n),
        delay: this.#p,
        shouldRetry: (a) => a instanceof Error ? r?.(a) ?? (!(a instanceof u) || a.code === "TIMEOUT") : !0
      },
      () => this.#y(o).then(() => this.response(o.action_id))
    ).catch((a) => {
      let c = null;
      if (a instanceof A && !a.retryAborted)
        if (!this.#o && !i && t !== "GameState")
          c = this.sendAndAwait("GameState", "GameState").catch(() => {
            throw this.#m(a);
          }).then(
            () => this.#A(
              t,
              e,
              n,
              r,
              !0
            )
          );
        else
          throw this.#m(a);
      if (!c)
        throw this.#m(
          a instanceof Error ? a : new Error("Unknown error")
        );
      return c;
    }) : Promise.reject(
      new u("Action could not be sent", "REQUEST_FAILED", "", {
        type: t,
        action: e,
        data: n,
        action_id: "",
        game_id: void 0,
        round_id: void 0
      })
    );
  }
  #E(t, e = !1) {
    if (!this.isReady)
      return e || this.#m(new Error("Not ready")), null;
    if (!this.#l && this.#f.length > 0)
      return e || this.#m(new Error("An action is already in progress")), null;
    const n = {
      ...t,
      action_id: R()
    };
    return this.isActionAllowed(n) ? n : (e || this.#m(
      new Error(`Action '${n.action}' is not allowed in the current state`)
    ), null);
  }
  #y(t) {
    return this.#f.push(t), Promise.resolve().then(() => {
      let e = !1;
      for (const n of this.#t)
        if (n.canSend(t) && n.send(t)) {
          e = !0;
          break;
        }
      if (!e) {
        const n = this.#f.lastIndexOf(t);
        throw n >= 0 && this.#f.splice(n, 1), new u(
          `Action '${t.action}' could not be sent`,
          "REQUEST_FAILED",
          t.action_id,
          t
        );
      }
      return this.#u > 0 && this.#b(t.action_id, this.#u), !0;
    });
  }
  /**
   * Wait for a response to a specific action id.
   *
   * If `options.timeout` is provided, the promise will reject if the action
   * does not resolve within the specified time in milliseconds.
   *
   * @param action_id - The id of action response to wait for.
   * @param options - The subscription options.
   * @returns A promise that resolves with the action response.
   */
  async response(t, {
    timeout: e
  } = {}) {
    let n = this.#e.get(t);
    return n || (n = [], this.#e.set(t, n)), e && e > 0 && this.#b(t, e), new Promise((r, i) => {
      n.push({ resolve: r, reject: i });
    });
  }
  #b(t, e) {
    let n = this.#r.get(t);
    n || (n = [], this.#r.set(t, n)), n.push(
      setTimeout(() => {
        this.#_({
          type: "Error",
          message: `Action '${t}' timed out after ${e}ms`,
          code: "TIMEOUT",
          action_id: t
        });
      }, e)
    );
  }
  reset() {
    this.#w = void 0, this.#e.clear(), this.#f.length = 0, this.#s.length = 0;
    for (const t of this.#r.values())
      for (const e of t)
        clearTimeout(e);
    this.#r.clear(), this.allowedActions.clear(), this.#d && this.allowedActions.add("Open");
  }
  close() {
    this.#o = !0, this.#h = "closed", this.reset(), this.#n.clear(), this.#i.clear();
    for (const t of this.#t)
      t.close();
  }
  /**
   * Handle an incoming message.
   *
   * Subclasses should override this method to handle incoming messages and
   * update the client state and the allowed actions set.
   *
   * @param _state - The incoming message.
   */
  handleUpdate(t) {
  }
  #_ = (t) => {
    let e;
    if (!t.action_id && t.type === "GameState" && (t.action_id = this.#f.find(
      (o) => o.type === "GameState"
    )?.action_id), t.action_id) {
      const o = this.#f.findIndex((a) => a.action_id === t.action_id);
      o >= 0 && (e = this.#f[o], this.#f.splice(o, 1));
    }
    const n = this.#e.get(t.action_id) ?? [];
    this.#e.delete(t.action_id);
    for (const o of this.#r.get(t.action_id) ?? [])
      clearTimeout(o);
    const r = this.#s.at(-1);
    if (t.action_id && t.action_id === r?.action_id)
      return;
    if (t.type === "Error") {
      const o = t, a = new u(o.message, o.code, o.action_id, e);
      for (const { reject: c } of n)
        c(a);
      return;
    }
    const i = t;
    this.#s.push(i), G(i) ? (this.#d = i.data.authenticated, this.allowedActions.clear(), this.#d ? this.allowedActions.add("Open") : (this.allowedActions.add("auth"), this.#m(
      new u("Authentication failed", "AUTH_FAILED", i.action_id)
    ))) : (U(i, "Open") || O(i)) && (this.#d = !0, this.#w = i.round_id), this.handleUpdate(i);
    for (const { resolve: o } of n)
      o(i.data);
  };
  #v = () => {
    if (this.#o)
      return;
    const t = this.#h;
    this.#h = "ready";
    for (const e of ["closed", "disconnected", "connecting"])
      if (this.#t.some((n) => n.readyState === e)) {
        this.#h = e;
        break;
      }
    if (this.#h !== t) {
      this.#h === "closed" && (this.#d = !1, this.#w = void 0, this.allowedActions.clear(), this.allowedActions.add(this.#a ? "auth" : "Open"));
      for (const e of this.#n)
        e(this.#h);
    }
  };
}
class H {
  #t = null;
  #e;
  #n = "closed";
  #i;
  #s;
  #a;
  #l = /* @__PURE__ */ new Set();
  #u = /* @__PURE__ */ new Set();
  #r;
  #c = globalThis.navigator?.onLine ?? !1;
  #p = !1;
  /**
   * @param url - The WebSocket URL to connect to.
   * @param supportedActions - The actions that this transport supports.
   *                           If not provided, all actions are supported.
   */
  constructor(t, {
    supportedActions: e,
    connectImmediately: n = !0,
    autoReconnect: r = !0,
    maxReconnectAttempts: i = 5,
    connectTimeout: o = 1e3
  } = {}) {
    this.#r = typeof e == "function" ? e : Array.isArray(e) ? (a) => e.includes(a.action) : () => !0, this.#e = t, this.#i = r, this.#s = i, this.#a = o, globalThis.window && (globalThis.window.addEventListener("online", () => {
      this.#c = !0, setTimeout(() => {
        this.connect().catch(console.error);
      }, 300);
    }), globalThis.window.addEventListener("offline", () => {
      this.#c = !1, this.#o("closed"), this.#t && this.#t.close();
    })), n && this.connect().catch(console.error);
  }
  get readyState() {
    return this.#n;
  }
  #o(t) {
    if (this.#n !== t) {
      this.#n = t;
      for (const e of this.#l)
        e(t);
    }
  }
  connect() {
    if (!this.#c)
      throw new Error("No network connection");
    return E(
      {
        maxAttempts: this.#i ? this.#s : 1
      },
      () => new Promise((t, e) => {
        this.#o("connecting"), this.#t = new WebSocket(this.#e);
        const n = new AbortController(), { signal: r } = n;
        let i = this.#a > 0 ? setTimeout(() => {
          n.abort(), e(new Error("Connection attempt timed out"));
        }, this.#a) : null;
        const o = () => {
          i && (clearTimeout(i), i = null), n.abort();
        };
        this.#t.addEventListener(
          "open",
          () => {
            o(), t();
          },
          { signal: r }
        ), this.#t.addEventListener(
          "error",
          (a) => {
            o(), e(
              a && a instanceof Error ? a : new Error(String("data" in a ? a.data : a))
            );
          },
          { signal: r }
        ), this.#t.addEventListener(
          "close",
          () => {
            o(), e(new Error("WebSocket connection closed"));
          },
          { signal: r }
        );
      })
    ).then(
      () => {
        this.#o("ready"), this.#t.addEventListener("close", () => {
          !this.#p && this.#i ? (this.#c = globalThis.navigator?.onLine ?? !1, this.connect().catch((t) => {
            console.error("Failed to reconnect", t), this.#o("disconnected");
          })) : this.#o("closed");
        }), this.#t.addEventListener("message", (t) => {
          try {
            const e = JSON.parse(t.data);
            try {
              for (const n of this.#u)
                n(e);
            } catch {
            }
          } catch (e) {
            console.error("Failed to parse message", t.data, e);
          }
        }), this.#t.addEventListener("error", (t) => {
          this.#o("closed"), console.error("WebSocket error", t);
        });
      },
      (t) => {
        console.error("Failed to connect", t), this.#o("closed");
      }
    );
  }
  get isReady() {
    return this.#n === "ready";
  }
  onMessage(t) {
    this.#u.add(t);
  }
  onReadyStateChanged(t) {
    this.#l.add(t), t(this.#n);
  }
  canSend(t) {
    return this.isReady && this.#r(t);
  }
  send(t) {
    if (this.#p || !this.#t || !this.isReady)
      return !1;
    let e;
    try {
      e = JSON.stringify(t);
    } catch (n) {
      return console.error("Failed to serialize message", t, n), !1;
    }
    return this.#t.send(e), !0;
  }
  close() {
    this.#p = !0, this.#t && (this.#o("closed"), this.#t.close());
  }
}
export {
  F as APIClient,
  B as AudioController,
  D as GameClient,
  u as GameError,
  A as RetryError,
  b as Sound,
  x as SoundSprite,
  H as WebSocketTransport,
  $ as formatCurrency,
  _ as get,
  N as isAction,
  G as isAuthenticate,
  O as isGameState,
  q as isGameUpdate,
  U as isState,
  w as mergeURL,
  M as nearlyEqual,
  v as post,
  S as request,
  E as runWithRetry,
  L as timer,
  R as uuid,
  y as validateObject,
  j as wait
};
