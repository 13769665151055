function k(e, t, r = "strict") {
  return y(e, t, { compare: r, maxDepth: 1 });
}
function y(e, t, r) {
  return u(e, t, p(r));
}
function g(e) {
  const t = p(e);
  return (r, s) => u(r, s, { ...t, cache: /* @__PURE__ */ new Map() });
}
const m = g({ compare: "strict" }), w = g({ compare: "loose" });
function p({
  compare: e = "strict",
  maxDepth: t = 1 / 0
} = {}) {
  return {
    cache: /* @__PURE__ */ new Map(),
    compare: e === "strict" ? Object.is : e === "loose" ? (r, s) => r == s || Number.isNaN(r) && Number.isNaN(s) : e,
    maxDepth: t
  };
}
const E = Object.getPrototypeOf(Uint8Array);
function h(e) {
  return e instanceof E;
}
const O = typeof globalThis.Element == "function";
function u(e, t, r, s = 0) {
  if (++s > r.maxDepth || !d(e) || !d(t))
    return r.compare(e, t);
  if (e === t)
    return !0;
  if (e.constructor !== t.constructor || O && e instanceof Element)
    return !1;
  let n = r.cache.get(e);
  if (!n)
    n = /* @__PURE__ */ new Set(), r.cache.set(e, n);
  else if (n.has(t))
    return !1;
  if (n.add(t), Array.isArray(e)) {
    const { length: o } = e;
    if (o !== t.length)
      return !1;
    for (let i = 0; i < o; i++)
      if (!u(e[i], t[i], r, s))
        return !1;
    return !0;
  }
  if (e instanceof Map) {
    if (!(t instanceof Map) || e.size !== t.size)
      return !1;
    for (const [o, i] of e)
      if (!t.has(o) || !u(i, t.get(o), r, s))
        return !1;
    return !0;
  }
  if (e instanceof Set) {
    if (!(t instanceof Set) || e.size !== t.size)
      return !1;
    for (const o of e)
      if (!t.has(o))
        return !1;
    return !0;
  }
  if (h(e)) {
    if (!h(t) || e.length !== t.length)
      return !1;
    for (let o = 0; o < e.length; o++)
      if (e[o] !== t[o])
        return !1;
    return !0;
  }
  if (e instanceof RegExp)
    return t instanceof RegExp && e.source === t.source && e.flags === t.flags && e.lastIndex === t.lastIndex;
  if (e.valueOf !== Object.prototype.valueOf && typeof e.valueOf == "function" && typeof t.valueOf == "function")
    return u(e.valueOf(), t.valueOf(), r, s);
  const f = Object.keys(e);
  if (Object.keys(t).length !== f.length)
    return !1;
  for (const o of f)
    if (!u(e[o], t[o], r, s))
      return !1;
  return !0;
}
function d(e) {
  return e !== null && typeof e == "object";
}
const S = class extends Array {
  read;
  update;
  constructor(t, r) {
    super(2), this.read = this[0] = t, this.update = this[1] = r;
  }
}, v = class {
  #s = 0;
  #i = !1;
  #n = !0;
  #t = /* @__PURE__ */ new Set();
  #e = [];
  signal = (t, { equals: r = m } = {}) => {
    const s = /* @__PURE__ */ new Set();
    let n = t;
    const f = () => {
      if (this.#n) {
        const i = this.#e.at(-1);
        i && !s.has(i) && (s.add(i), i.onCleanup(() => s.delete(i)));
      }
      return n;
    }, o = (i) => {
      if (i = i instanceof Function ? i(n) : i, !r(n, i)) {
        n = i;
        for (const l of s)
          this.#t.add(l);
        this.#r();
      }
    };
    return new S(f, o);
  };
  untracked = (t) => {
    const r = this.#n;
    this.#n = !1;
    const s = t();
    return this.#n = r, s;
  };
  #r() {
    if (this.#i)
      return;
    this.#i = !0;
    const t = Array.from(this.#t).filter((s) => s.isMemo);
    for (const s of t)
      s.update(), this.#t.delete(s);
    if (this.#i = !1, this.#s > 0)
      return;
    const r = Array.from(this.#t);
    this.#t.clear();
    for (const s of r)
      s.update();
  }
  #o(t, { isMemo: r = !1, abortSignal: s } = {}) {
    if (s && s.aborted)
      return () => {
      };
    let n;
    const f = /* @__PURE__ */ new Set();
    let o;
    const i = () => {
      for (const c of f)
        c();
      if (f.clear(), o)
        try {
          o();
        } catch (c) {
          console.error("Error during effect cleanup:", c);
        }
    };
    s && s.addEventListener("abort", i, { once: !0 });
    const l = () => {
      if (i(), this.#e.includes(n))
        throw new Error("Cyclic dependency detected");
      this.#e.push(n);
      try {
        o = t();
      } catch (c) {
        throw i(), c;
      } finally {
        this.#e.pop();
      }
    };
    return n = {
      isMemo: r,
      update: l,
      onCleanup(c) {
        f.add(c);
      }
    }, l(), i;
  }
  effect = (t, r) => this.#o(t, r);
  memo = (t, r) => {
    const [s, n] = this.signal(void 0, r);
    return this.#o(() => n(t()), { ...r, isMemo: !0 }), s;
  };
  batch = (t) => {
    this.#s++;
    try {
      t();
    } finally {
      --this.#s === 0 && this.#r();
    }
  };
};
function x() {
  return new v();
}
class M {
  #s;
  #i;
  #n;
  #t = /* @__PURE__ */ new Map();
  #e = /* @__PURE__ */ new Map();
  constructor({ storageKey: t, effect: r, signal: s }) {
    this.#s = t, this.#i = r, this.#n = s;
  }
  #r(t = !1) {
    if (!t && this.#t.size > 0)
      return;
    this.#t.clear();
    const r = localStorage.getItem(this.#s);
    if (r)
      for (const [s, n] of Object.entries(JSON.parse(r)))
        this.#t.set(s, n);
  }
  #o() {
    localStorage.setItem(this.#s, JSON.stringify(Object.fromEntries(this.#t)));
  }
  #f(t, r, s) {
    this.#r(!0), this.#t.set(t, {
      ...this.#t.get(t) ?? {},
      [r]: s
    }), this.#o();
  }
  signal(t, r, s) {
    this.#r();
    const n = this.#n(this.#t.get(t)?.[r] ?? s);
    return this.#e.set(`${t}/${String(r)}`, {
      update: n.update,
      remove: this.#i(() => {
        this.#f(t, r, n.read());
      })
    }), n;
  }
  get(t, r) {
    return this.#r(), this.#t.get(t)?.[r];
  }
  set(t, r, s) {
    const n = `${t}/${String(r)}`;
    this.#e.has(n) ? this.#e.get(n).update(s) : this.#f(t, r, s);
  }
  delete(t, r) {
    this.#r();
    const s = this.#t.get(t);
    s && (delete s[r], this.#o());
    const n = `${t}/${String(r)}`;
    this.#e.has(n) && (this.#e.get(n).remove(), this.#e.delete(n));
  }
  clear(t) {
    this.#r(), this.#t.has(t) && (this.#t.delete(t), this.#o());
    const r = Array.from(this.#e.keys()).filter(
      (s) => s.startsWith(`${t}/`)
    );
    for (const s of r)
      this.#e.get(s).remove(), this.#e.delete(s);
  }
}
const a = x();
function N(e, t) {
  return a.signal(e, t);
}
function q(e) {
  return a.untracked(e);
}
function I(e, t) {
  return a.effect(e, t);
}
function $(e, t) {
  return a.memo(e, t);
}
function T(e) {
  a.batch(e);
}
export {
  M as LocalStorageRepository,
  T as batch,
  x as createStore,
  I as effect,
  y as equal,
  g as equalFunc,
  w as looseStructuralEqual,
  $ as memo,
  k as shallowlyEqual,
  N as signal,
  m as structuralEqual,
  q as untracked
};
