import games from './games.json';

import * as client from '@monkey-tilt/client';
import * as state from '@monkey-tilt/state';
import * as ui from '@monkey-tilt/ui';
import * as utils from '@monkey-tilt/utils';

import '@monkey-tilt/ui/style.scss';

export type UnmountFn = () => void;
type RunFn = (options: Record<string, unknown>) => UnmountFn;

declare global {
    interface Window {
        MT_client: typeof client;
        MT_state: typeof state;
        MT_ui: typeof ui;
        MT_utils: typeof utils;
        [key: string]: unknown;
    }
}

window.MT_client = client;
window.MT_state = state;
window.MT_ui = ui;
window.MT_utils = utils;

const baseUrl = new URL(
    globalThis && globalThis.document.currentScript instanceof HTMLScriptElement
        ? globalThis.document.currentScript.src
        : location.href,
);

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = new URL('./style.css', baseUrl).href;
document.head.appendChild(link);

const loadedGames = new Map<string, Promise<RunFn>>();
const mountedGames = new Map<HTMLElement | string, UnmountFn>();

export function run(options: {
    readonly gameId: keyof typeof games;
    readonly container: HTMLElement | string;
    readonly [key: string]: unknown;
}): Promise<UnmountFn> {
    const { gameId } = options;

    if (!loadedGames.has(gameId)) {
        loadedGames.set(
            gameId,
            new Promise((resolve, reject) => {
                const { src, namespace } = games[gameId];
                const script = document.createElement('script');
                script.src = new URL(src, baseUrl).href;
                script.onload = () => {
                    const game = window[namespace] as { run?: RunFn } | undefined;
                    if (game && typeof game.run == 'function') {
                        resolve(game.run);
                    } else {
                        reject(
                            new Error(
                                `Game "${gameId}" does not export ${namespace}.run() function`,
                            ),
                        );
                    }
                };
                script.onerror = reject;
                document.body.appendChild(script);
            }),
        );
    }

    const { container } = options;

    return loadedGames.get(gameId)!.then((run) => {
        if (mountedGames.has(container)) {
            mountedGames.get(container)!();
        }

        const unmount = run(options);
        let isMounted = true;

        mountedGames.set(container, () => {
            if (isMounted) {
                isMounted = false;
                mountedGames.delete(container);
                unmount();
            }
        });

        return unmount;
    });
}
